.account-wrapper {
    height: calc(100vh - var(--header));
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.account-section {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45vw;
    transition: all 0.6s ease;

}

.account-section-active{
    width: 55vw;
    border-right: 1px solid hsl(var(--border));
    border-left: 1px solid hsl(var(--border));

    background-color: hsl(var(--primary) /.05);
}

@media screen and (max-width:1000px){
    .account-wrapper{
        flex-direction: column;
        height: calc(100vh - var(--header));

    }

    .account-section{
        height: 30%;
        width: 100vw;
        transition: height 0.6s ease;


    }

    .account-section-active{
        width: 100vw;
        height: 70%;
        border: none;
        border-top: 1px solid hsl(var(--border));
        border-bottom: 1px solid hsl(var(--border));
    }
    
}

.account {

    box-sizing: border-box;
    color: hsl(var(--foreground));
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
}

.account-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    flex-direction: column;
    padding: 8px 0;
    box-sizing: border-box;

    width: 100%;
}

.account-row:nth-child(2){
    gap: 8px;
}


.account h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.account-row span{
    color: hsl(var(--foreground) /.5);
    font-size: 12px;
}

.account-row label {
    font-weight: 400;
    font-size: 12px;
    color: hsl(var(--foreground) /.8);
}

.account-row input {
    background-color: transparent;
    border: 1px solid hsl(var(--border));
    color: hsl(var(--foreground));

    font-size: 12px;


    outline: none;
    border-radius: 4px;

    padding: 8px 16px;
    box-sizing: border-box;

    transition: all .4s ease;
    width: 100%;
}

.account-row::placeholder{
    font-family: inherit;
}


.account-row input:hover,
.account-row input:focus,
.account-row input:active {
    border: 1px solid hsl(var(--primary) /.3)
}


.account-row button {
    width: 100%;
    padding: 6px 64px;
    box-sizing: border-box;

    font-family: inherit;
    font-size: 12px;
    font-weight: 300;
    color: hsl(var(--foreground));

    background-color: hsl(var(--input));


    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;

    cursor: pointer;
    transition: all .4s ease;
}

.account-row button:hover {
    background-color: hsl(var(--input) /.5);

    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    .account-row h1{
        font-size: 18px;
    }

    .account-row span{
        text-align: center;
        font-size: 10px;
    }
    .account-row{
        padding: 4px 0;
    }
}

.account-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0;
}

.account-hr {
    flex-grow: 1;
    height: 1px;
    background-color: hsl(var(--border));
    margin: 0 10px;
}

.account-row p {
    font-size: 12px;
    color: hsl(var(--foreground));
    margin: 0;
}

.button-with-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border: 1px solid hsl(var(--border)) !important;
}

.alt-flow{
    display: inline-block;
}

.coming-soon-hover {
    position: relative; /* Ensure the :after pseudo-element is positioned relative to this element */
}

.coming-soon-hover::after {
    content: 'Coming Soon';
    position: absolute;
    bottom: -25px;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.4s ease; /* Smoothly transition the opacity */
    color: hsl(var(--font-color) / .8);
    font-size: 12px;
    background-color: hsl(var(--primary) /.1);
    padding: 2px 6px;
    box-sizing: border-box;
    border-radius: 50px;
}

.coming-soon-hover:hover::after,
.coming-soon-hover:active::after,
.coming-soon-hover:focus::after {
    opacity: 1; /* Make the text visible on hover */
}

