.header-wrapper{
    width: 100vw;
    height: var(--header);

    background-color: hsl(var(--background) / .5);
    backdrop-filter: blur(20px);
    border-bottom: 1px solid hsl(var(--border) / .5);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 2;
}

.header{
    width: 100%;
    height: 100%;

    padding: 0 5%;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: hsl(var(--foreground) / .6);

}

.header-left img{
    width: 30px;
}


.header-right{
    display: flex;
    gap: 16px;

}

.nav-link{
    text-decoration: none;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    transition: .4s all ease;
}

.nav-link:hover{
    color: hsl(var(--primary));

}

.nav-active{
    font-weight: 500;
    color: hsl(var(--foreground) / .8);

}

.theme-switch{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid hsl(var(--border));
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    transition: .4s all ease;
}

.theme-switch:hover{
    border: 1px solid hsl(var(--primary) /.6);
}

.theme-switch img{
    width: 12px;
    opacity: 0.8;
}

