.rm-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: hsla(223, 84%, 5%, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.rm-hr {
    width: 100%;
    height: 1px;
    background-color: hsl(var(--foreground) /.2);
    padding: 0 8px;
    box-sizing: border-box;
    margin: 10px 0;
}

.rm-container {
    border: 1px solid hsl(var(--border));
    background-color: hsl(var(--background));
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    width: 40vw;
}



.rm-container span {
    margin: 0;
}

.rm-section {
    padding: 8px;
    box-sizing: border-box;
}

.rm-close {
    outline: none;
    border: 1px solid hsl(var(--border));
    background-color: transparent;
    border-radius: 50px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: hsl(var(--foreground))
}

.rm-title {
    font-size: 24px;
    font-weight: 500;

}

.rm-subtitle {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px !important;
}

.rm-subtitle strong{
    font-weight: 500;
}

.rm-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rating-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.stars-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    gap: 8px;
    box-sizing: border-box;
}

.star {
    color: hsl(var(--primary) /.5);
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.response-head {
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.response-submit{
    outline: none;
    border: 1px solid hsl(var(--primary) / .2);
    border-radius: 2px;
    padding: 2px 24px;
    box-sizing: border-box;
    font-family: inherit;
    cursor: pointer;
    background-color: hsl(var(--primary) /.1);
    color: hsl(var(--foreground));
    transition: all .3s ease;
    margin-top: 14px;
}

.response-submit:hover{
    background-color: hsl(var(--primary) /.15);
    border-radius: 4px;
    border: 1px solid hsl(var(--primary) / .5);

}


.response-content textarea {
    height: 100px;
    outline: none;
    border: 1px solid hsl(var(--border));
    background-color: hsl(var(--primary) /.1);
    color: hsl(var(--foreground));
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    resize: none;
}


.reccomend-head{
    padding-bottom: 8px;
    box-sizing: border-box;
}



.slides-item label {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: hsl(var(--muted));
    font-weight: 500;
    font-size: 12px;
    padding: 2px 4px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .rm-container {
        width: 90vw;
    }
}

@media screen and (max-width: 500px) {
    .rm-container {
        width: 100vw;
        border-radius: 0px;
        border-left: none;
        border-right: none;
    }
}

.slider-wrapper {
    width: 100%;
    overflow: hidden;
}

.slider-container {
    overflow: auto;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.slider-container::-webkit-scrollbar {
    height: 8px;    
    width: 10px; 
    border: 1px solid #d5d5d5;
    background-color: hsl(var(--primary) /.1);
    border-top: none;
    cursor: pointer;
  }

  .slider-container::-webkit-scrollbar-thumb {
    height: 8px;    
    width: 10px; 
    border: 1px solid #d5d5d5;
    background-color: hsl(var(--primary) / .5);
    border-top: none;
    cursor: pointer;
  }

.reccomend-slideshow {
    display: flex;
    transition: transform 0.5s ease;
    gap: 10px;

}

.slides-item {
    position: relative;
    background-color: hsl(var(--primary-background));
    border: 1px solid hsl(var(--border));
    height: 150px;
    cursor: pointer;
}

.slides-item img {
    height: 100%;
    overflow: hidden;
}

.slider-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 16px;
    box-sizing: border-box;
}

.slider-arrow {
    font-size: 20px;
    cursor: pointer;
}

.slides-more {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin-left: 16px;
}

.more-btn {
    margin-top: 2px;
    font-size: 24px;
    color: hsl(var(--foreground) /.7);
}


.sign-up-bonus-div {
    margin-top: 35px;
    box-sizing: border-box;
    text-align: center;
}


.sign-up-bonus {
    padding: 10px 30px;
    box-sizing: border-box;

    border-radius: 500px;
    border: 1px solid rgba(255, 217, 0, 0.537);
    background-color: rgba(255, 217, 0, 0.084);
    color: inherit;
    text-decoration: none;
    transition: all .4s ease;
}

.sign-up-bonus:hover{
    border: 1px solid gold;
    background-color: rgba(255, 217, 0, 0.14);
    font-weight: 500;
    padding: 10px 70px;
}