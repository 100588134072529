.Profile {
    width: 100vw;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;


}


.profile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid hsl(var(--border));
    padding: 16px;
    box-sizing: border-box;
    width: 640px;
    background: hsl(var(--accent) /.3);


}

.pfp-img {
    width: 100px;
    /* position: absolute;
    bottom: -50px;
    left: 30px; */
    background-color: hsl(var(--background));
    border: 1px solid hsl(var(--border));
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
}


.profile-header {
    position: relative;
    border-radius: 8px;
    width: 100%;
    height: 180px;
    background:
        linear-gradient(to right, hsl(var(--background)), transparent),
        radial-gradient(circle at 75% 25%,
            hsl(var(--primary) / .7),
            transparent 200px),
        radial-gradient(circle at 90% 75%,
            rgba(255, 0, 255, 0.5),
            transparent 400px),
        radial-gradient(circle at 75% 75%,
            rgba(255, 100, 100, 0.6),
            transparent 400px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid hsl(var(--border));

    box-shadow: 0px 1px 1px hsl(var(--foreground) / .1);
        transition: all 0.4s ease;

}

.profile-header-details {
    padding-bottom: 2px;
    box-sizing: border-box;
}

.logout-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: hsl(var(--background) / .7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 3px 4px 4px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid hsl(var(--border));
    cursor: pointer;
    color: hsl(var(--foreground));
    transition: all .4s ease;
}

.logout-btn:hover {
    background-color: hsl(var(--background));
}

.wand-btn {
    top: 56px
}

.profile-header p {
    color: hsl(var(--foreground) / .7);
    font-weight: 500;
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.profile-header p:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
}


.profile-stats {
    width: 100%;
    /* height: 100px; */
    display: flex;
    gap: 16px
}

@media screen and (max-width: 600px) {

    .Profile {
        padding: 0px;
        padding-bottom: var(--header);
        box-sizing: border-box;
        align-items: flex-start;
        width: 100vw;
    }

    .profile-stats {
        flex-direction: column;
    }

    .profile-wrapper {
        width: 100%;
        border-radius: 0px;
        border: none;
    }
}

.profile-stat-bx {
    flex: 1;
    border-radius: 8px;
    border: 1px solid hsl(var(--border));
    padding: 24px 18px;
    box-sizing: border-box;
    color: hsl(var(--foreground) / .7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 1px 1px hsl(var(--foreground) / .1);
    transition: all 0.4s ease;

}

.profile-stat-bx p {
    margin: 0;
}

.stat-title {
    color: hsl(var(--foreground) / .5);
    font-weight: 400;
    font-size: 14px;
}

.stat-result {
    color: hsl(var(--foreground) / .7);
    font-weight: 600;
    font-size: 18px;
    width: 100%;
}

.profile-rated {
    width: 100%;
    height: 200px;
    border-radius: 8px;
    border: 1px solid hsl(var(--border));
    padding: 18px;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px hsl(var(--foreground) / .1);

}

.profile-rated h1{
    margin: 0;
    font-size: 18px;
    padding: 0px 4px 12px 4px;
    font-weight: 700;
    color: hsl(var(--foreground) / .7);
}

.table-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.table-container::-webkit-scrollbar {
    width: 4px;
}

.table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 8px;
    table-layout: fixed;

}


.table th,
.table td {
    padding: 8px;
    text-align: left;
    font-size: 14px;
    color: hsl(var(--foreground) / .6);
}

.table th {
    font-weight: 500;
    color: hsl(var(--foreground) / .8);
    background-color: hsl(var(--background));

}

.table td a{
    color: inherit;
}



.table thead th {
    background-color: hsl(var(--accent));
    position: sticky;
    top: 0;
}



.table td {
    transition: .4s all ease;
}

.table tr:hover td {
    background-color: hsl(var(--border) / .75);
}

.table th:hover {
    background-color: hsl(var(--accent));
}

.table tr th:nth-child(1) {
    width: 20%;
}

.table tr th:nth-child(2) {
    width: 20%;
}

.table tr th:nth-child(3) {
    width: 30%;
}

.table tr th:nth-child(4) {
    width: 30%;
}

@media screen and (max-width:500px) {

    .table th,
    .table td {
        padding: 4px;
        font-size: 12px;
    }

    .table th {
        font-weight: 500;
        font-size: 14px;
    }

    .table tr th:nth-child(2) {
        width: 20%;
    }
}