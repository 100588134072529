.home{
    width: 100%;
    /* height: 100%; */

    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */

    padding: calc(var(--header)/2) 5%;
    box-sizing: border-box;
}

.home-title{
    font-weight: 800;
    font-size: 64px;
    color: hsl(var(--foreground) / .8);
    margin: 0;
}

.home-subtitle{
    color: hsl(var(--foreground) / .7);
    font-weight: 500;

}

.home-title a{
    text-decoration: none;
}

.home-image{
    width: 100%;
}

.home-image img {
    width: 100%;
}

.home-action-btns {
    display: flex;
    gap: 8px
}

.home-action{
    text-decoration: none;
    color: inherit;
    padding: 4px 24px 4px 4px;
    box-sizing: border-box;
    border: 1px solid hsl(var(--primary) / .5);
    border-radius: 50px;
    background-color: hsl(var(--primary-foreground));
    color: hsl(var(--primary) / .7);
    transition: .4s all ease;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 16px;
    /* width: 200px; */

}

.home-action span {
    text-decoration: none;
}



.home-action:hover{
    border: 1px solid hsl(var(--primary) / .9);

    color: hsl(var(--primary) / .9)
}

.sign-up-btn{
    text-decoration: none;
    color: inherit;
    padding: 4px 24px 4px 4px;
    box-sizing: border-box;
    color: hsl(var(--primary) / .7);
    display: flex;
    align-items: center;
    gap: 16px;
}

.home-icon {
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid hsl(var(--primary) / .7);
    border-radius: 50px;
}

.home-action-btns{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sign-up-info{
    display: flex;
}

@media screen and (max-width: 600px) {
    .home-action-btns{
        align-items: flex-start;
        flex-direction: column;
    }
}