.Markets{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: var(--header);
    box-sizing: border-box;
}

.Market {
    width: 100%;
    padding: calc(var(--header) / 2) 10%;
    box-sizing: border-box;
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    border-top: 1px solid hsl(var(--border))
}



.Market:nth-child(1){
    border-top: none;
}

.market-title{
    font-size: 28px;
    padding: calc(var(--header) / 2 + 28px) calc(10% + 56px) 0px calc(10% + 28px);
    box-sizing: border-box;
    color: hsl(var(--foreground));
}

.market-title h1{
    margin: 0;
    font-weight: 700;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 1300px) {
    .market-title{
        padding: calc(var(--header) / 2) calc(10%) 0px calc(10%);
    }
}

@media screen and (max-width: 600px) {
    .market-title h1{
        font-size: 48px;
    }
}

.market-hr{
    width: calc(100% - 20%);
    height: 1px;
    margin: 5%;
    background-color: hsl(var(--foreground) / .3);
}
.market-section {
    padding: 28px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

}

@media screen and (max-width: 1300px) {
    .Market{
        flex-direction: column;
    }

    .market-section{
        height: auto;
        padding: 0;
    }
}


.mkt-image {
    width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    border: 4px solid hsl(var(--border))
}

.mkt-image img {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .mkt-image img{
        width: 100%;
    }
}

.mkt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.mkt-header a {
    text-decoration: none;
    color: hsl(var(--primary) /.9);
    transition: .4s ease all;

}

.mkt-header a:hover{
    text-decoration: underline;
}

.mkt-title {
    font-size: 32px;
    font-weight: 600;


}

.mkt-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

@media screen and (max-width: 1500px) {
    .mkt-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
}

.mkt-act-btn {
    padding: 4px 24px;
    box-sizing: border-box;

    outline: none;
    border: 1px solid transparent;

    cursor: pointer;
    background-color: hsl(var(--muted) /.5);
    color: hsl(var(--muted-foreground));
    border: 1px solid hsl(var(--primary) /.2);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s ease;
}

.mkt-act-btn:hover {
    background-color: hsl(var(--primary) / .1);
    border: 1px solid hsl(var(--primary) /.5);
}

.mkt-tags-feedback{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    box-sizing: border-box;
}

@media screen and (max-width:1000px ) {
    .mkt-tags-feedback{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
    }
}

.avgrating{
    font-weight: 500;
    font-size: 20px;
    color: hsl(var(--primary));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.avgrating div {
    height: 20px;
}

.avgrating p{
    margin: 0;
    font-size: 18px;
}

.mkt-tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 16px;
    font-weight: 500;
    font-size: 20px;
    color: hsl(var(--primary));
}

.mkt-tags a {
    text-decoration: none;
    color: hsl(var(--primary));
}

.mkt-tags a:hover {
    text-decoration: underline;
}

.mkt-description {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    color: hsl(var(--muted-foreground));
}

@media screen and (max-width:600px) {
    .mkt-description{
        font-size: 14px;
    }

    .mkt-tags a {
        font-size: 16px;
    }

    .mkt-actions{
        /* justify-content: space-between;
        width: 100%; */
        /* gap: 4px; */
    }

    .mkt-act-btn{
        border-radius: 8px;
    }
}